export const environment = {
    apiUrl: 'https://apidev2.digicoffer.com/cors/professional',
    emailApi:"https://dev.utils.mail.digicoffer.com/api/v1",
    doc2pdf:"https://dev.utils.doc2pdf.digicoffer.com/api/v1/doc2pdf",
    lateXAPI: 'https://devapi.latex.digicoffer.com',
    paymentgatway:"https://dev2.payment.digicoffer.com/",
    chatops:' https://dev.utils.chat.digicoffer.com',
    xmppDomain: 'devchat.vitacape.com',
    product: 'lauditor',
    production: true,
  };
 


