<!-- CONTENT DIALOG -->
<div class="overviewDialog">
    <form [formGroup]="contentForm">
        <div class="closeDialog">
            <i class="fa fa-times xClose" (click)="closeDialog()" aria-hidden="true"></i>
        </div>

        <div class="openDialog">
            <div class="form-group">
                <label for="title" class="lableTitle">Content Title</label>
                <input formControlName="contentTitle" maxlength="255" [(ngModel)]="contentTitle" placeholder="Enter title" type="text" (ngModelChange)="prependHyphen($event)" class="text-size form-control createDoc" name="title"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,().-_ ]|^ /g,'')" (keydown)="onKeyDown1($event)" (keypress)="onKeyPress($event)" (input)="restricttextSpace($event)">
                <p class="textCount">{{contentTitle.length}}/255</p>
            </div>

            <div class="form-group">
                <label for="title" class="lableTitle">Text</label>
                <textarea formControlName="contentData" maxlength="5000" [(ngModel)]="contentData" (input)="restrictSplCharacteratFirst($event)" (keypress)="onKeyPress($event)" placeholder="Enter your content" class="text-size form-control textareaDoc"  rows="3" cols="50"></textarea>
                <p class="textCount">{{contentData.length}}/5000</p>
            </div>

            <div class="overviewSave">
                <button type="submit" (click)="save()" class="btn btn-default btnsave savecls">Save</button>
            </div>

        </div>
    </form>
</div>